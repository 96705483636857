<template>
  <div class="wiki-search-modal w-full">
    <header class="!mb-6">
      <div class="text-2xl font-medium">
        Поиск по базе знаний
      </div>
    </header>

    <div class="!px-2 cursor-pointer">
      <input
        type="text"
        v-model="search"
        placeholder="Поис по базе знаний"
        @input="onSearch"
        class="w-full rounded-lg bg-[#f3f3f3] h-14 !px-3 !mb-6"
      />
    </div>

    <div v-if="list.length" class="h-[400px] overflow-auto custom-scrollbar !px-2">
      <div
        v-for="(item, index) of list"
        :key="index"
        class="flex !items-center !p-4 !mb-3 cursor-pointer border-b border-[#e1e1e1]"
        @click="onChose(item)"
      >
        <UIcon name="file" class="!w-6 !h-6 fill-[#d6d6d6] !mr-3" />
        <div>
          <div class="!font-medium">{{ item.title }}</div>
          <div v-if="item.structure" class="!mt-1 !text-sm text-[#7f7f7f]">
            {{ item.structure }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="h-[400px] flex items-center justify-center text-lg text-gray-400">
      Воспользуйтесь поиском
    </div>
  </div>
</template>

<script>
import { coreApi } from '@/api';
import WikiStructureNodeTree from "@/components/redesign/wiki/structure/WikiStructureNodeTree.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: 'WikiSearchModal',
  components: { WikiStructureNodeTree },
  props: {
    structure: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      search: '',
      list: [],
    }
  },
  computed: {
    ...mapState('main', ['user']),
  },
  methods: {
    ...mapActions('wiki', ['init']),
    async onSearch() {
      let result = await coreApi.wikiSearch({
        search: this.search,
      })

      if (result?.length) {
        result = result
          .filter((i) => i.userId === this.user.id)
          .filter((i) => {
            return this.findElementByIdAndType(this.structure, i.id, '2');
          });

        this.list = result.map((i) => {
          return {
            ...i,
            structure: this.findArticleById(this.structure, Number(i.id)),
          }
        })
      } else {
        this.result = []
      }

      console.log('result', result);
    },
    findArticleById(articles, targetId) {
      const result = {}

      const findArticleRecursive = (node, parentIdList) => {
        for (const item of node) {
          const currentId = item.idArticle || null
          const currentTitle = item.title || null

          if (currentId === targetId) {
            // result.title = currentTitle
            result.parent = parentIdList.reverse()
            return
          }

          const newParentList = parentIdList.concat([currentTitle])

          if (item.node && item.node.length > 0) {
            findArticleRecursive(item.node, newParentList)
          }
        }
      }

      findArticleRecursive(articles, [])

      if (result?.parent) {
        return result.parent.join(' / ');
      } else {
        return ''
      }
    },
    async onChose(item) {
      const article = this.findElementByIdAndType(this.structure, item.id,2);

      if (article?.id) {
        this.$router.push({
          path: '/wiki',
          query: { id: article?.id },
        })

        await this.init({
          user: this.user,
          route: this.$route,
        })

        this.$emit('close')
      }
    },

    findElementByIdAndType(dataArray, id, type) {
      for (const element of dataArray) {
        const result = this.findInNode(element, id, type);
        if (result) {
          return result
        }
      }

      return null
    },
    findInNode(node, id, type) {
      if (node.idArticle == id && node.type == type) {
        return node
      }

      for (const childNode of node.node) {
        const result = this.findInNode(childNode, id, type)
        if (result) return result
      }

      return null
    },
  },
}
</script>

<style lang="scss">
.wiki-search-modal {}
</style>
