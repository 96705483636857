<template>
  <div class="wiki-structure-node-tree">
    <div v-if="isFolder" class="folder" :class="{ active: active() }">
      <div class="wrapper" @click="choseFolder">
        <div class="i-chevron" :class="{ active: isMoreNode }">
          <UIcon name="chevron-right" />
        </div>
        <div class="i-folder">
          <UIcon name="folder" />
        </div>
        <div
          class="title"
          v-b-tooltip.hover.v-secondary
          :title="node.title"
        >
          {{ node.title }}
        </div>

        <div class="setting">
          <div class="i-delete" @click="deleteFolder">
            <UIcon name="delete" />
          </div>
          <div class="i-plus" @click="addFolder">
            <UIcon name="plus" />
          </div>
        </div>
      </div>

      <div v-if="node.node.length" class="nodes">
        <WikiStructureNodeTree
          v-show="isMoreNode"
          v-for="(nodeInner, index) of node.node"
          :key="index"
          :node="nodeInner"
          @deleteFolder="node.node.splice(index, 1)"
          @addFolder="node.node[index].node.push($event)"
        />
      </div>
    </div>
    <div v-else class="article" @click="choseFolder">
      <div class="i-file">
        <UIcon name="file"/>
      </div>
      <div
        class="title"
        v-b-tooltip.hover.v-secondary
        :title="node.title"
      >
        {{ node.title }}
      </div>
    </div>
  </div>
</template>

<script>
import WikiStructureNodeTree from '@/components/redesign/wiki/structure/WikiStructureNodeTree';
import { mapMutations, mapActions, mapState } from 'vuex';
import IconDelete from "@/assets/images/svg/delete.svg";

export default {
  name: 'WikiStructureNodeTree',
  components: {
    IconDelete,
    WikiStructureNodeTree,
  },
  props: {
    node: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isMoreNode: false,
    }
  },
  computed: {
    ...mapState('wiki', ['currentPage']),
    isFolder() {
      return this.node?.type === 1
    },
  },
  methods: {
    ...mapActions('wiki', ['nextId']),
    ...mapMutations('wiki', ['setCurrentPage']),
    active() {
      return this.currentPage?.id === this.node.id;
    },
    choseFolder() {
      console.log('this.node', this.node);
      if (this.node.type === 1) {
        this.isMoreNode = !this.isMoreNode;
      }
      this.setCurrentPage(this.node);
    },
    deleteFolder() {
      this.$emit('deleteFolder');
    },
    async addFolder() {
      const id = await this.nextId();

      this.$emit('addFolder', {
        title: `Новая категория ${id}`,
        id,
        type: 1,
        node: [],
      });
      this.isMoreNode = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.wiki-structure-node-tree {
  position: relative;
  width: 100%;
  cursor: pointer;

  .folder {
    .wrapper {
      height: 33px;
      display: flex;
      align-items: center;
      padding: 0 6px;
      border-radius: 6px;

      & > .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #858585;
        height: 100%;
        line-height: 33px;
        width: 100%;
      }

      .setting {
        visibility: hidden;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        top: 0;
        right: 10px;
        height: 33px;
        min-width: 40%;
        border-radius: 0 6px 6px 0;
        background: linear-gradient(270.1deg, #f1f1f1 43.53%, rgba(238, 238, 238, 0.01) 72.18%);

        .icon {
          fill: #AFAFAF;
        }

        .i-delete,
        .i-plus {
          flex-shrink: 0;
          flex-grow: 0;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;

          &:hover {
            background: #e6e6e6;
            .icon {
              fill: #5b5b5b;
            }
          }
        }

        .i-plus .icon {
          width: 12px;
          height: 12px;
        }
      }

      &.active {
        & > .title {
          color: #373737;
        }

        .icon {
          fill: #373737;
        }
      }

      &:hover {
        background: #f1f1f1;
        .setting {
          visibility: visible;
        }
      }
    }

    .nodes {
      position: relative;
      margin-left: 15px;
    }

    &.active {
      & > .wrapper {
        & > .title {
          color: #373737;
          font-weight: 500;
        }

        .i-folder,
        .i-file,
        .i-chevron {
          .icon {
            fill: #373737;
          }
        }
      }
    }
  }

  .article {
    height: 33px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 24px;

    & > .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #858585;
    }

    &:hover {
      background: #ededed;
      border-radius: 6px;
    }

    &.active {
     & > .title {
      color: #373737;
     }

      .icon {
        fill: #373737;
      }
    }
  }

  .i-chevron,
  .i-folder,
  .i-file {
    flex-shrink: 0;
    flex-grow: 0;
    width: 22px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px;

    .icon {
      fill: #AFAFAF;
    }
  }

  .i-file .icon {
    width: 13px;
    height: 11px;
  }

  .i-folder .icon {
    width: 13px;
    height: 11px;
  }

  .i-chevron {
    width: 12px;
    transition: all .3s;

    &.active {
      transform: rotate(90deg);
    }
  }
}
</style>


// Чекаю папка или файл

