<template>
  <div class="u-dropdown" :data-open="state">
    <!-- <button ref="toggle" class="toggle" @click="toggleMenu"> -->
    <div class="toggle-wrapper">
      <div ref="toggle" class="toggle" @click="toggleMenu"></div>
      <slot />
    </div>
    <!-- </button> -->
    <div v-click-outside="onClickOutside" class="select-dropdown-list blue-scrollbar">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="select-dropdown-list-item"
        :title="item[titleKey]"
        @click="() => selectItem(item)"
      >
        {{ item[titleKey] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UDropdown',
  props: {
    items: {
      type: [Array, Set],
      required: true
    },
    titleKey: {
      type: String,
      default: 'title'
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [Object, String],
      required: true
    },
  },
  data() {
    return {
      state: false
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    closeMenu() {
      this.state = false;
    },
    toggleMenu() {
      this.state = !this.state;
    },
    onClickOutside(e) {
      if (e.target !== this.$refs.toggle) {
        this.closeMenu();
      }
    },
    selectItem(item) {
      this.selected = item;
      this.closeMenu();
    }
  }
};
</script>

<style lang="scss" scoped>
.u-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    color: silver;
    font-size: 14px;
    transition: all ease-in-out 0.2s;
    margin-bottom: 5px;
  }

  .toggle-wrapper {
    position: relative;
    .toggle {
      cursor: pointer;
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  

  .select-dropdown-list {
    z-index: 100;
    display: none;
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    flex-direction: column;
    top: 41px;
    border: 1px solid #c6c6c6;
    background-color: #fff;
    border-radius: 6px;

    &-item {
      height: 42px;
      min-height: 42px;
      line-height: 42px;
      color: #202020;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
      transition: 0.3s ease-in-out;

      &:hover {
        background: rgb(241, 241, 241);
      }

      &:not(:first-child) {
        border-top: 1px solid rgb(205, 205, 205);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &[data-open='true'] {
    .toggle {
      font-weight: 500;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: calc(100% - 20px);
        background-color: silver;
        height: 1px;
      }
      svg {
        transform: rotate(180deg);
      }
    }
    .select-dropdown-list {
      display: flex;
    }
  }
}
</style>
