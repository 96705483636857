<template>
  <div class="wiki-content">
    <template v-if="isPage">
      <header>
        <input type="text" v-model="title" />

        <div class="nav">
          <div class="active" v-if="!isFolder">
            <label for="active">
              <input type="checkbox" v-model="active" id="active" name="active" />
              {{ active ? 'Активен' : 'Не активен' }}
            </label>
          </div>
          <UDropdown
            id="new"
            v-model="newNode"
            :items="listNewNode"
            title-key="title"
          >
            <UButton v-if="isFolder" variant="gray" >
              <UIcon name="plus" />
              Добавить
            </UButton>
          </UDropdown>
<!--          <div class="i-delete" @click="deleteFolder(currentPage)">-->
          <div class="i-delete" @click="isDeleteFolder = true">
            <UIcon name="delete" />
          </div>
          <div v-if="!isFolder" class="i-copy !ml-2" @click="copyLink">
            <UIcon name="copy" class="w-4 h-4" />
          </div>
          <!--
          <div class="i-menu" @click="deleteFolder">
            <UIcon name="menu" />
          </div>
          -->
          <button class="button-save" @click="saveArticle">Сохранить</button>
        </div>
      </header>

      <div class="wiki-inner-content">
        <WikiContentFolder v-if="isFolder" :content="content" />
        <div v-else>
          <WikiContentArticle v-if="isBodyUpdate" v-model="body" :content="content" />
        </div>
      </div>
    </template>
    <div v-else class="empty">
      <UIcon name="illustrations/wiki-empty-category" />
      <div class="title">Выберите или создайте категорию</div>
      <UButton
        variant="soft"
        @click="addNode({
          title: 'Новая категория',
          type: 1
        });"
      >
        Новая категорию
      </UButton>
    </div>

    <UModal v-model="isDeleteFolder" width="400px">
      <div class="text-3xl text-center mb-6 !font-medium">Вы уверены, что хотите удалить?</div>

      <div class="flex justify-end !mt-4">
        <UButton @click="isDeleteFolder = false" variant="gray" class="mr-4">Закрыть</UButton>
        <UButton @click="deleteFolderCurrent(currentPage)">Удалить</UButton>
      </div>
    </UModal>

    <!-- <div class="delete" @click="deleteFolder(currentPage)">deleteFolder</div> -->
  </div>
</template>

<script>
import { coreApi } from '@/api';
import { mapActions, mapState } from 'vuex';
import UButton from '@/components/redesign/ui/UButton';
import WikiContentArticle from '@/components/redesign/wiki/content/article/WikiContentArticle'
import WikiContentFolder from '@/components/redesign/wiki/content/folder/WikiContentFolder'
import UDropdown from '@/components/redesign/ui/UDropdown'
import FullText from '@/components/base/FullText'
import UModal from "@/components/redesign/ui/UModal.vue";

export default {
  name: 'WikiContent',
  components: {
    UModal,
    UButton,
    FullText,
    WikiContentArticle,
    WikiContentFolder,
    UDropdown,
  },
  computed: {
    ...mapState('main', ['user']),
    ...mapState('wiki', ['currentPage']),
    isPage() {
      return this.currentPage?.id;
    },
    content() {
      return this.currentPage;
    },
    isFolder() {
      return this.currentPage?.type === 1;
    },
    title: {
      get() {
        return this.content.title;
      },
      set(value) {
        this.content.title = value;
        this.updateArticle();
      }
    },
    active: {
      get() {
        return this.content.active;
      },
      set(value) {
        this.content.active = value;
        this.updateArticle();
      }
    },
    dateUpdate: {
      get() {
        return this.content.dateUpdate;
      },
      set(value) {
        this.content.dateUpdate = value;
      }
    },
  },
  data() {
    return {
      isDeleteFolder: false,
      isBodyUpdate: true,
      body: [],
      newNode: '',
      listNewNode: [
        { title: 'Категория', type: 1 },
        { title: 'Статья', type: 2 }
      ]
    };
  },
  watch: {
    async newNode(value) {
      if (value.type === 1) {
        this.addNode({
          title: 'Новая категория',
          type: 1
        });
      } else if (value.type === 2) {
        const id = await coreApi.wikiArticleAdd({
          userId: this.user?.id,
          title: 'Новая статья',
        })
        this.addNode({
          title: 'Новая статья',
          type: 2,
          idArticle: id,
          active: true,
          dateUpdate: new Date()
        });
      }
    },
    async content() {
      if (this.content.type == 2) {
        this.isBodyUpdate = false;
        const article = await coreApi.wikiArticleGet(this.content);
        this.body = JSON.parse(article.body || '[]');
        this.isBodyUpdate = true;
      }
    },
    currentPage() {
      // if (this.currentPage?.type === 2) {
      //   console.log('currentPage', this.currentPage);
      //   this.$router.replace(`/wiki?article=35`);
      // } else {
      //   this.$router.replace(`/wiki`);
      // }
    }
  },
  methods: {
    ...mapActions('wiki', ['deleteFolder', 'addFolder', 'nextId', ]),
    deleteFolderCurrent(currentPage) {
      this.isDeleteFolder = false;
      this.deleteFolder(currentPage)
    },
    async copyLink() {
      console.log('this.content', this.content)
      await navigator.clipboard.writeText(
    `https://app.eddy24.ru/wiki?id=${this.content.id}`
      );
      this.$toast("Ссылка на статью скопирована. Можете отправить сотруднику");
    },
    async addNode(data) {
      const id = await this.nextId();

      this.addFolder({
        ...data,
        id,
        node: [],
      });

      this.newNode = '';
    },
    updateArticle() {
      this.dateUpdate = new Date();
      const { type, idArticle } = this.content;
      if (type == 2) {
        const params = {
          id: idArticle,
          dateUpdate: new Date(),
          active: this.active,
          title: this.title,
          body: JSON.stringify(this.body || [])
        };

        coreApi.wikiArticleUpdate(params);
      }
    },
    saveArticle() {
      this.dateUpdate = new Date();
      const { idArticle } = this.content;
      const params = {
        id: idArticle,
        dateUpdate: new Date(),
        active: this.active,
        title: this.title,
        body: JSON.stringify(this.body || [])
      };

      this.$toast("Данные успешно сохранены");

      coreApi.wikiArticleUpdate(params);
    }
  },
};
</script>

<style lang="scss">
.wiki-content {
  width: 100%;
  height: 85vh;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #DFDFDF;

    & > input {
      height: 32px;
      width: 100%;
      background: none;
      border: none;
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      .active {
        label {
          display: flex;
          align-items: center;
          margin-right: 16px;
          font-size: 16px;

          input {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }
      }

      .button {
        height: 36px;
        margin-right: 8px;
        width: max-content;
        background: #f1f1f1;
        color: #818181;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        border-radius: 4px;

        .icon {
          fill: #818181;
          width: 15px;
          height: 15px;
        }

        &:hover {
          background: #e1e1e1;
        }
      }

      .button-save {
        height: 36px;
        margin-right: 8px;
        width: max-content;
        background: #3ea5d9;
        color: #fff;
        padding: 0 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        border-radius: 4px;
        margin-left: 16px;
        border: none;

        &:hover {
          background: #e1e1e1;
        }
      }

      .i-copy {
        margin-right: 8px;
      }

      .i-copy,
      .i-menu,
      .i-delete {
        cursor: pointer;
        flex-shrink: 0;
        flex-grow: 0;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: #f1f1f1;

        .icon {
          fill: #818181;
        }

        &:hover {
          background: #e1e1e1;
        }
      }
    }
  }

  .wiki-inner-content {
    padding: 16px;
  }

  & > .empty {
    width: 100%;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {
      width: 250px;
      margin-bottom: 50px;
    }

    .title {
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 25px;
    }

    .button {
      width: max-content;
      width: 230px;
      font-size: 13px;
      font-weight: 500 !important;
    }
  }
}
</style>
