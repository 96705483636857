<template>
  <div class="wiki-structure">
    <div class="top">
      <div
        v-if="false"
        class="nav"
      >
        <div class="link">
          <UIcon name="wiki-main" />
          Главная
        </div>
        <div class="link">
          <UIcon name="wiki-settings" />
          Настройки
        </div>
      </div>
      <div
        v-if="structureData.length"
        class="wrapper custom-scrollbar"
      >
        <div class="bg-white rounded-lg h-10 !mb-4 w-full flex items-center !pl-3 cursor-pointer" @click="isModalSearch = true">
          Поиск
        </div>
        <div class="title">
          Категории и статьи
        </div>
        <WikiStructureNodeTree
          v-for="(node, index) of structureData"
          :key="index"
          :node="node"
          @deleteFolder="structureData.splice(index, 1)"
          @addFolder="structureData[index].node.push($event)"
        />
      </div>
    </div>

    <UButton
      variant="soft"
      @click="addFolder"
    >
      <UIcon name="plus" />
      Создать
    </UButton>

    <UModal v-model="isModalSearch" close>
      <WikiSearchModal :structure="structureData" @close="isModalSearch = false" />
    </UModal>
  </div>
</template>

<script>
import WikiStructureNodeTree from '@/components/redesign/wiki/structure/WikiStructureNodeTree'
import UButton from '@/components/redesign/ui/UButton'
import { mapActions, mapMutations, mapState } from 'vuex'
import WikiSearchModal from "@/components/redesign/wiki/WikiSearchModal";
import UModal from "@/components/redesign/ui/UModal.vue";

export default {
  name: 'WikiStructure',
  components: {
    UModal,
    WikiSearchModal,
    WikiStructureNodeTree,
    UButton,
  },
  data() {
    return {
      isModalSearch: false,
    }
  },
  computed: {
    ...mapState('wiki', ['structure']),
    structureData: {
      get() {
        return this.structure
      },
      set(value) {
        this.setStructure(value)
      },
    },
  },
  methods: {
    ...mapMutations('wiki', ['setStructure']),
    ...mapActions('wiki', ['nextId']),
    async addFolder() {
      const id = await this.nextId()
      this.structureData.push({
        title: `Новая категория ${id}`,
        id,
        type: 1,
        node: [],
      })
    },
  },
}
</script>

<style lang="scss">
.wiki-structure {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 260px;
  width: 260px;
  height: 85vh;
  padding-right: 16px;
  border-right: 2px solid #DFDFDF;

  & > .top > .wrapper {
    width: 100%;
    overflow: auto;

    & > .title {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #C6C6C6;
      margin-bottom: 12px;
      padding: 0 6px;
    }
  }

  .button {
    margin-top: 10px;
    min-height: 39px;
    max-height: 39px;
    font-size: 13px;

    .icon {
      margin-right: 10px;
      width: 13px;
      height: 13px;
    }

    &:hover {
      background: #e0f4ff;
    }
  }

  .nav {
    display: flex;
    flex-direction: column;
    padding: 0 6px;
    margin-bottom: 20px;

    & > div {
      padding: 0 0 16px 0;
      color: #858584;
      cursor: pointer;

      .icon {
        margin-right: 10px;
      }
    }
  }
}
</style>
