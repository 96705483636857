<template>
  <div class="wiki">
    <Structure />
    <Content />
  </div>
</template>

<script>
// 1 - folder, 2 - article
import Structure from '@/components/redesign/wiki/structure/WikiStructure'
import Content from '@/components/redesign/wiki/content/WikiContent'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Wiki',
  components: {
    Structure,
    Content,
  },
  computed: {
    ...mapState('main', ['user']),
  },
  methods: {
    ...mapActions('wiki', ['init']),
  },
  async mounted() {
    await this.init({
      user: this.user,
      route: this.$route,
    })
  },
}
</script>

<style lang="scss">
.wiki {
  width: 100%;
  display: flex;
}
</style>
