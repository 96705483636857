<template>
  <div class="wiki-content-article">
    <FullText v-model="body" />
  </div>
</template>

<script>
import FullText from '@/components/base/FullText'

export default {
  name: 'WikiContentArticle',
  components: { FullText },
  props: {
    value: {
      type: [Array, Object],
      default: () => {}
    },
  },
  computed: {
    body: {
      get() {
        console.log('this.value', this.value)
        if (!this.value.length) {
          return [
            {
              id : 1,
              type : "paragraph",
              data : {
                text : "Тут будет ваш текст"
              }
            },
          ];
        }
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss">
.wiki-content-article {
  width: 100%;
  background: #fff;
  border-radius: 16px;
}
</style>
