<template>
  <div class="wiki-content-folder">
    <div
        v-if="content.node.length"
        class="table-wiki"
    >
      <table>
        <thead>
        <tr>
          <th />
          <th>Название</th>
          <th>Последнее обновление</th>
          <th>Статус</th>
          <th />
        </tr>
        </thead>
        <transition-group
            is="draggable"
            v-model="content.node"
            tag="tbody"
            :name="!drag ? 'flip-list' : null"
            animation="150"
            @start="drag = true"
            @end="drag = false"
        >
          <tr
              v-for="(item, index) in content.node"
              :key="index"
          >
            <td
                class="move-hover"
                @click="choseNode(item)"
            >
              <UIcon
                  name="move"
                  class="move"
              />
            </td>
            <td
                class="title-node"
                @click="choseNode(item)"
            >
              <div
                  v-if="isFolder(item)"
                  class="block"
              >
                <UIcon name="folder" />
              </div>
              <div
                  v-else
                  class="block"
              >
                <UIcon name="file" />
              </div>
              {{ item.title }}
            </td>
            <td @click="choseNode(item)">
              {{ item.type === 1 ? '-' : `${formateDate(item.dateUpdate, 'date')} ${formateDate(item.dateUpdate, 'time')} ` }}
            </td>
            <td @click="choseNode(item)">
              <div
                  v-if="(item.type === 2)"
                  class="status"
                  :class="[ item.active ? 'active' : 'deactive' ]"
              >
                {{ item.active ? 'Активен' : 'Неактивен' }}
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td>
              <div class="settings">
                <div
                    class="block"
                    @click="deleteItem(item)"
                >
                  <!-- <UIcon name="menu" /> -->
                  <UIcon name="delete" />
                </div>
              </div>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
    <div
        v-else
        class="empty"
    >
      <UIcon name="illustrations/wiki-empty-category" />
      <div class="title">
        Тут еще ничего нет
        <br>
        Создайте статью или категорию
      </div>
      <div class="button-group">
        <UButton
            variant="soft"
            @click="newNode({type: 1});"
        >
          Создать категорию
        </UButton>
        <UButton
            variant="soft"
            @click="newNode({type: 2});"
        >
          Создать статью
        </UButton>
      </div>
    </div>
  </div>
</template>

<script>
import { coreApi } from '@/api'
import { mapMutations, mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import UButton from '@/components/redesign/ui/UButton'

export default {
  name: 'WikiContentFolder',
  components: { draggable, UButton },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      drag: false,
    }
  },
  computed: {
    ...mapState('main', ['user']),
  },
  methods: {
    ...mapActions('wiki', ['addFolder', 'nextId', 'deleteFolder']),
    ...mapMutations('wiki', ['setCurrentPage']),
    isFolder(item) {
      return item?.type === 1
    },
    deleteItem(item) {
      this.deleteFolder(item)
    },
    choseNode(node) {
      this.setCurrentPage(node);
    },
    async newNode(value) {
      if (value.type === 1) {
        this.addNode({
          title: 'Новая категория',
          type: 1,
        })
      } else if (value.type === 2) {
        const id = await coreApi.wikiArticleAdd({
          userId: this.user?.id,
          title: 'Новая статья',
        })
        this.addNode({
          title: 'Новая статья',
          type: 2,
          idArticle: id,
          active: true,
          dateUpdate: new Date(),
        })
      }
    },
    async addNode(data) {
      const id = await this.nextId()

      this.addFolder({
        ...data,
        id,
        node: [],
      })

      this.newNode = ''
    },
    formateDate(value, format) {
      const date = new Date(value)

      let options = {}

      if (format.includes('second')) {
        options = { ...options, minute: '2-digit', second: '2-digit' }
      }

      if (format.includes('time')) {
        options = { ...options, hour: '2-digit', minute: '2-digit' }
      }

      if (format.includes('date')) {
        options = {
          ...options, day: '2-digit', month: '2-digit', year: 'numeric',
        }
      }

      if (format.includes('monthlong')) {
        options = { ...options, month: 'long' }
      }

      return new Intl.DateTimeFormat('ru-Ru', options).format(date)
    },
  },
  mounted() {
    console.log('test', this.content)
  },
}
</script>

<style lang="scss">
.wiki-content-folder {
  width: 100%;

  .table-wiki {
    table {
      width: 100%;
      border-collapse: collapse;

      th {
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: #919191;
      }

      td {
        color: #0F0F0F;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;

        &.move-hover {
          cursor: move;
        }

        .status {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          border-radius: 100px;
          padding: 2px 15px;
          width: max-content;

          &.active {
            color: #16A154;
            background: #E1EFE8;
          }

          &.deactive {
            color: #EA5455;
            background: #F7E8E8;
          }
        }

        .move {
          fill: #CFCFCF;
        }

        &.title-node {
          .block {
            display: inline-block;
            height: 20px;
            width: 20px;
            margin-right: 12px;

            .icon {
              height: 20px;
              width: 20px;
              fill: #AFAFAF;
            }
          }
        }

        .settings {
          display: flex;
          .block {
            cursor: pointer;
            flex-shrink: 0;
            flex-grow: 0;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: #f1f1f1;
            margin-right: 12px;

            .icon {
              fill: #818181;
            }

            &:hover {
              background: #e1e1e1;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      th, td {
        border-bottom: 1px solid #DFDFDF;
        padding: 0 20px;
        height: 70px;
      }
    }
  }

  & > .empty {
    width: 100%;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {
      width: 250px;
      margin-bottom: 50px;
    }

    .title {
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 25px;
      text-align: center;
    }

    .button-group {
      display: flex;

      .button:first-child {
        margin-right: 10px;
      }
    }

    .button {
      width: max-content;
      width: 230px;
      font-size: 13px;
      font-weight: 500 !important;
    }
  }
}
</style>
